import { ColumnState } from "ag-grid-community";
import { createContext, Dispatch, SetStateAction } from "react";
import { getOpportunities } from "../../api";
import {
  GetGroupOpportunityParams,
  OpportunityType,
  TabHeader,
  TableStateFilter,
} from "../../api/types";
import { getDaysSinceLatestActivity } from "../utils";
import { SearchField } from "./OpportunityView";

type translateParamsProps = {
  startRow: number | undefined;
  filterModel: { [key: string]: any };
  sortModel: ColumnState[];
  oppTypeId: any;
  paginationPageSize: number;
  queryString: string;
  agGridOptions: any;
  alwaysFilteringBy?: any;
  searchFields?: SearchField[];
};

export type VisibleField = {
  fieldName: string;
  headerName: string;
  selected: boolean;
};

export type SortingFilterModels = {
  filterModel: { [key: string]: any };
  sortModel: ColumnState[];
};

export const getFilterKey = (opportunityTypeName: string) => {
  return `${opportunityTypeName.replaceAll(" ", "_")}_CURRENT_FILTER`;
};

export const getStateFilterKey = (opportunityTypeName: string) => {
  return `${opportunityTypeName.replaceAll(" ", "_")}_STATE_FILTER`;
};

export const getCurrentQueryKey = (opportunityTypeName: string) => {
  return `${opportunityTypeName.replaceAll(" ", "_")}_FILTER`;
};

// Multi Select Fields
const multiSelectFields: string[] = ["additional_fields.therapeutic_area"];

// build api params
export const translateParams = ({
  startRow,
  filterModel,
  sortModel,
  oppTypeId,
  paginationPageSize,
  queryString,
  agGridOptions,
  alwaysFilteringBy,
  searchFields,
}: translateParamsProps) => {
  var nextPage: number = 1;

  if (startRow) {
    nextPage = Math.floor(startRow / paginationPageSize) + 1;
  }

  function join(t: any, a: any, s: any) {
    function format(m: any) {
      let f = new Intl.DateTimeFormat("en", m);
      return f.format(t);
    }
    return a.map(format).join(s);
  }

  const date_format = [
    { year: "numeric" },
    { month: "2-digit" },
    { day: "2-digit" },
  ];

  //  let a = [{day: 'numeric'}, {month: 'short'}, {year: 'numeric'}];
  //  let s = join(new Date, a, '-');

  const params: GetGroupOpportunityParams = {
    // page to be requested from backend 1-index
    page: nextPage,
    limit: paginationPageSize,
    opportunity_types: oppTypeId,
    q: queryString,
  };

  if (searchFields?.length) {
    params.search_fields = searchFields
      .filter((param) => param.selected)
      .map((v) => v.value)
      .join(",");
  }
  var sort: String[] = [];
  // Parse SortModel
  if (sortModel) {
    if (sortModel.length >= 2) {
      sortModel.sort((a, b) => a.sortIndex! - b.sortIndex!);
    }
    sortModel.forEach((col) => {
      // For reverse sorting logic, where sorting is handled within the case switch rather than at the end. In such cases, we do not want to repeat the sorting at the end which will undo the sorting
      var skip = false;
      // Parse based on field names from columnDefs and convert to corresponding string name on API
      if (col.sort === "asc" || col.sort === "desc") {
        if (col.colId.startsWith("additional_fields.")) {
          sort?.push(col.colId.replace(".", "__"));
        } else {
          switch (col.colId) {
            case "funnel_stage.name": {
              sort?.push("funnel_stage__order");
              break;
            }
            case "name": {
              sort?.push("name");
              break;
            }
            case "source_organization?.name": {
              sort?.push("source_org");
              break;
            }
            case "opportunity_type.name": {
              sort?.push("opportunity_type__name");
              break;
            }
            case "owner.name": {
              sort?.push("owner__first_name");
              break;
            }
            case "created_at": {
              sort?.push("created_at");
              break;
            }
            case "updated_at": {
              sort?.push("updated_at");
              break;
            }
            case "last_activity": {
              sort?.push("latest_last_activity");
              break;
            }
            case "interaction_date": {
              sort?.push("interaction_date");
              break;
            }
            case "latest_comment.created_at": {
              sort?.push("latest_comment");
              break;
            }
            case "partner.name": {
              sort?.push("partner__first_name");
              break;
            }
            case "originator.name": {
              sort?.push("originator__first_name");
              break;
            }
            case "days_since_last_activity":
              sort?.push("latest_last_activity");
              break;
            case "status":
              sort?.push("status_order");
              break;
            case "days_since_created": {
              // Sorting logic for days_since_created is reversed and needs to be handled here as the field is duplicated
              if (col.sort === "asc") {
                sort?.push("-created_at");
              } else if (col.sort === "desc") {
                sort?.push("created_at");
              }
              skip = true;
              break;
            }
          }
        }
        if (sort && col.sort === "desc" && skip === false) {
          sort[sort.length - 1] = "-" + sort[sort.length - 1];
        }
        skip = false;
      }
      // descending order
      if (col.sort === "desc") {
        params.sort = "-" + params.sort;
      }
    });
    params.sort = sort.toString();
  }

  // Parse filterModel
  // Boilerplate for converting from filterModel to django filters
  var additional_fields: { [key: string]: any } = {};
  if (alwaysFilteringBy) {
    filterModel = { ...filterModel, ...alwaysFilteringBy };
  }

  Object.entries(filterModel).forEach(([k, v]) => {
    if (k === "groups") {
    } else if (k === "opportunity_type.name" && v.values) {
      let oopTypes: number[] = [];
      v.values.forEach((value: string) =>
        oopTypes.push(getOpportunityType(agGridOptions.api.oppTypes, value).id)
      );
      params.opportunity_types = oopTypes.join(",");
    } else if (k === "funnel_stage.name" && v.values) {
      params.stages = v.values.join(",");
    } else if (k.startsWith("additional_fields") && v.filterType === "number") {
      switch (v.type) {
        case "equals": {
          additional_fields[k.replace(".", "__").concat("__exact")] = v.filter;
          break;
        }
        case "greaterThan": {
          additional_fields[k.replace(".", "__").concat("__gt")] = v.filter;
          break;
        }
        case "lessThan": {
          additional_fields[k.replace(".", "__").concat("__lt")] = v.filter;
          break;
        }
      }
    } else if (k.startsWith("additional_fields.") && v.filterType === "date") {
      switch (v.type) {
        case "equals": {
          additional_fields[k.replace(".", "__").concat("__exact")] = join(
            new Date(v.dateFrom),
            date_format,
            "-"
          );
          break;
        }
        case "greaterThan": {
          additional_fields[k.replace(".", "__").concat("__gt")] = join(
            new Date(v.dateFrom),
            date_format,
            "-"
          );
          break;
        }
        case "lessThan": {
          additional_fields[k.replace(".", "__").concat("__lt")] = join(
            new Date(v.dateFrom),
            date_format,
            "-"
          );
          break;
        }
        case "inRange": {
          additional_fields[k.replace(".", "__").concat("__gte")] = join(
            new Date(v.dateFrom),
            date_format,
            "-"
          );
          additional_fields[k.replace(".", "__").concat("__lte")] = join(
            new Date(v.dateTo),
            date_format,
            "-"
          );
          break;
        }
      }
    } else if (k.startsWith("additional_fields.")) {
      if (v.filter && v.type === "contains") {
        additional_fields[k.replace(".", "__").concat("__icontains")] =
          v.filter;
      } else {
        if (v.values && multiSelectFields.includes(k)) {
          additional_fields[k.replace(".", "__").concat("__contains")] =
            v.values;
        } else if (v.values) {
          additional_fields[k.replace(".", "__").concat("__in")] = v.values;
        }
      }
    } else if (v && v.filterType === "date") {
      switch (v.type) {
        // case "equals": {params.last_activity = join(new Date(v.dateFrom), date_format, "-"); break;}
        // case "greaterThan": {params.last_activity = join(new Date(v.dateFrom), date_format, "-"); break;}
        // case "lessThan": {params.last_activity = join(new Date(v.dateFrom), date_format, "-"); break;}
        case "inRange": {
          var dates = "";
          dates = dates.concat(join(new Date(v.dateFrom), date_format, "-"));
          dates = dates.concat(",");
          dates = dates.concat(join(new Date(v.dateTo), date_format, "-"));
          if (k === "created_at") {
            params.created_at = dates;
          } else if (k === "last_activity") {
            params.last_activity = dates;
          } else if (k === "latest_comment.created_at") {
            params.latest_comment = dates;
          } else if (k === "interaction_date") {
            params.interaction_date = dates;
          }
          break;
        }
      }
    } else if (k === "owner.name" && v.values) {
      params.owners = v.values?.toString();
    } else if (k === "source_organization.name" && v.values) {
      params.organizations = v.values?.toString();
    } else if (k === "partner.name") {
      params.partners = v.values?.toString();
    } else if (k === "originator.name") {
      params.originators = v.values?.toString();
    } else if (k === "tags" && v.values) {
      params.tags = v.values?.toString();
    } else if (k === "status" && v.values && v.values !== "") {
      params.status = v.values?.toString().toLowerCase();
    } else if (k === "partners" && v.values) {
      params.partners = v.values?.toString();
    } else if (k === "team_members" && v.values) {
      params.team_members = v.values?.toString();
    } else if (k === "contacts" && v.values) {
      params.contacts = v.values?.toString();
    } else if (k === "investors" && v.values) {
      params.investors = v.values?.toString();
    } else if (k === "in_team" && v.values) {
      if (v.values[0] === "Yes") {
        params.in_team = true;
      } else {
        params.in_team = false;
      }
    } else if (v.filterType === "number") {
      switch (v.type) {
        case "Greater than or equals": {
          var dates = "";
          const upperBoundDate = new Date();
          // If created at = 30 Jun 2023, days_since_created = 6 as of 6 Jul 2023 (excludes 30 Jun 2023).
          // To filter >= 6 and get the correct result, use 6 Jul 2023 - 6 = 30 Jun 2023
          upperBoundDate.setDate(upperBoundDate.getDate() - v.filter);
          var upperBoundDateSet = "";
          upperBoundDateSet = upperBoundDate.toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          dates = dates.concat(
            join(new Date(upperBoundDateSet), date_format, "-")
          );
          dates = dates.concat("__lte");
          if (k === "days_since_created") {
            params.created_date_range = dates;
          }
          if (k === "days_since_last_activity") {
            params.last_activity_since_range = dates;
          }
          break;
        }
        case "Less than or equals": {
          var dates = "";
          const lowerBoundDate = new Date();
          lowerBoundDate.setDate(lowerBoundDate.getDate() - v.filter - 1);
          var previousDateSet = "";
          previousDateSet = lowerBoundDate.toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          dates = dates.concat(
            join(new Date(previousDateSet), date_format, "-")
          );
          dates = dates.concat("__gte");
          if (k === "days_since_created") {
            params.created_date_range = dates;
          }
          if (k === "days_since_last_activity") {
            params.last_activity_since_range = dates;
          }
          break;
        }
        case "equals": {
          var dates = "";
          const exactDate = new Date();
          exactDate.setDate(exactDate.getDate() - v.filter - 1);
          var exactDateSet = "";
          exactDateSet = exactDate.toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          dates = dates.concat(join(new Date(exactDateSet), date_format, "-"));
          dates = dates.concat("__exact");
          if (k === "days_since_created") {
            params.created_date_range = dates;
          } else if (k === "days_since_last_activity") {
            params.last_activity_since_range = dates;
          }
          break;
        }
      }
    } else if (k === "groups" && v.values) {
      params.groups = v.values?.toString();
    }
    params.additional_fields = additional_fields;
  });
  return params;
};

// fetch data
export const fetchOpportunities = (
  agGridOption: any,
  opportunityParams: GetGroupOpportunityParams,
  paginationPageSize: number,
  setGridVisible?: Dispatch<SetStateAction<boolean>>,
  setIsLoading?: Dispatch<SetStateAction<boolean>>
) => {
  getOpportunities(opportunityParams)
    .then((response) => {
      if (response && !agGridOption.api.isDestroyed()) {
        //set total pages
        agGridOption.api.totalPages = response.data.pages;
        agGridOption.api.totalItems = response.data.total;

        // get lastrow, Will not show loading icon in case row has no data
        const lastRow =
          response.data.items.length === paginationPageSize &&
          opportunityParams.page < agGridOption.api.totalPages
            ? -1
            : (agGridOption.api.totalPages - 1) * paginationPageSize +
              response.data.items.length;

        if (setGridVisible) {
          setGridVisible(response.data.total > 0);
        }

        agGridOption.success({
          rowData: response.data.items,
          rowCount: lastRow,
        });
      }
    })
    .finally(() => {
      setIsLoading && setIsLoading(false);
    });
};

export type TableStateSessionProps = {
  selectedState: TableStateFilter;
  previousState: TableStateFilter;
  tableStateName: string;
  tableStateId: string;
};

export interface OpportunityContextData {
  agGrid: any;
  setAgGrid: Dispatch<any>;
}

// opportunity context
export const OpportunityContext = createContext<OpportunityContextData>({
  agGrid: null,
  setAgGrid: (() => undefined) as Dispatch<any>,
});

// get opportunity type
export const getOpportunityType = (
  opportunityTypes: OpportunityType[],
  typeName: string
) => {
  if (opportunityTypes && opportunityTypes.length > 0) {
    const opportunityType = opportunityTypes.filter(
      (type) => type.name?.toUpperCase() === typeName?.toUpperCase()
    )[0];
    return opportunityType;
  }
  //Default value;
  return {
    name: "",
    id: 0,
    funnel: [],
    has_destination_organization: false,
    additional_fields: null,
    created_at: "",
  };
};

export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key) ?? "";
};

export const setLocalStorage = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

// get opportunity type
export const getFunnels = (
  opportunityTypes: OpportunityType[],
  typeName: string | undefined | null
) => {
  if (typeName) {
    const opportunityType = opportunityTypes.filter(
      (type) => type.name.toUpperCase() === typeName.toUpperCase()
    )[0];
    return opportunityType.funnel;
  }
  return [];
};

export const createExpandedGridOptions = () => {
  let date = new Date();

  const getRowHeight = (params: any) => {
    const rowData = params.data; // Get the row data

    const contentKeys = [
      "description",
      "latest_comment.text",
      "additional_fields.status_note",
    ];

    // Function to get nested properties based on the dot notation
    const getNestedProperty = (obj: any, path: string) => {
      return path.split(".").reduce((result, key) => result?.[key], obj);
    };

    // Set a maximum height for the row content (adjust this value as needed)
    const maxRowHeight = 125;
    const incrementFactor = 0.4;

    // Calculate the height based on the combined content length of the specified keys
    let height = rowData && rowData.target_organization ? 60 : 30;

    if (rowData && typeof rowData === "object") {
      contentKeys.forEach((key) => {
        const content = getNestedProperty(rowData, key);
        if (content) {
          height += content.length * incrementFactor; // Increment the height based on a fraction of content length
        }
      });
    }

    // Set the height to the maximum value if it exceeds the maxRowHeight
    return Math.min(height, maxRowHeight);
  };

  return {
    getContextMenuItems(params: any) {
      return [];
    },
    getRowHeight: getRowHeight,
  };
};

//Generate Menu context Items with CSV Export
export const createGridOptions = (fileName: string) => {
  let date = new Date();

  return {
    getContextMenuItems(params: any) {
      return [
        {
          name: "CSV Export",
          action: function () {
            params.api.exportDataAsCsv({
              fileName: fileName + "_" + date.toLocaleDateString(),
              processCellCallback: function (cell: any) {
                // If the cell value is an object, extract the name property
                if (
                  typeof cell.value === "object" &&
                  cell.value !== null &&
                  !Array.isArray(cell.value)
                ) {
                  if (cell.value.hasOwnProperty("name")) {
                    return cell.value.name;
                  }
                }
                if (
                  typeof cell.value === "object" &&
                  cell.value !== null &&
                  !Array.isArray(cell.value)
                ) {
                  if (cell.value.hasOwnProperty("title")) {
                    return cell.value.title;
                  }
                }
                // If cells are calculated fields
                if (cell.column.colId === "days_since_last_activity") {
                  return getDaysSinceLatestActivity([
                    cell.node.data.last_activity,
                    cell.node.data.latest_comment?.created_at,
                    cell.node.data.created_at,
                  ]).toString();
                }
                if (cell.column.colId === "days_since_created") {
                  return getDaysSinceLatestActivity([
                    cell.node.data.created_at,
                  ]).toString();
                }
                // If the cell value is an array of objects, join the name property
                if (Array.isArray(cell.value) && cell.value.length > 0) {
                  return cell.value.map((obj: any) => obj.name).join(", ");
                }
                // Otherwise, return the cell value as is
                return cell.value;
              },
            });
          },
        },
        {
          name: "Excel Export",
          action: function () {
            params.api.exportDataAsExcel({
              fileName: fileName + "_" + date.toLocaleDateString(),
              processCellCallback: function (cell: any) {
                // If the cell value is an object, extract the name property
                if (
                  typeof cell.value === "object" &&
                  cell.value !== null &&
                  !Array.isArray(cell.value)
                ) {
                  if (cell.value.hasOwnProperty("name")) {
                    return cell.value.name;
                  }
                }
                if (
                  typeof cell.value === "object" &&
                  cell.value !== null &&
                  !Array.isArray(cell.value)
                ) {
                  if (cell.value.hasOwnProperty("title")) {
                    return cell.value.title;
                  }
                }
                if (cell.column.colId === "days_since_last_activity") {
                  return getDaysSinceLatestActivity([
                    cell.node.data.last_activity,
                    cell.node.data.latest_comment?.created_at,
                    cell.node.data.created_at,
                  ]).toString();
                }
                if (cell.column.colId === "days_since_created") {
                  return getDaysSinceLatestActivity([
                    cell.node.data.created_at,
                  ]).toString();
                }
                // If the cell value is an array of objects, join the name property
                if (Array.isArray(cell.value) && cell.value.length > 0) {
                  return cell.value.map((obj: any) => obj.name).join(", ");
                }
                // Otherwise, return the cell value as is
                return cell.value;
              },
            });
          },
        },
      ];
    },
  };
};

export const partnershipsTabs: TabHeader[] = [
  {
    id: "business_development",
    opportunityTypeName: "Business Development",
  },
  {
    id: "pr_marketing",
    opportunityTypeName: "PR & Marketing",
  },
  {
    id: "referral",
    opportunityTypeName: "Referral",
    className: "hidden sm:block",
  },
  {
    id: "fundraising",
    opportunityTypeName: "Fundraising",
    className: "hidden sm:block",
  },
  {
    id: "others",
    opportunityTypeName: "Others",
    className: "hidden sm:block",
  },
];
